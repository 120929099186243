import request from "@/api/request";

//get classes
export const getClassesForTeacher = async (type, uid) => {
  try {
    const res = await request({
      url: `/events/v1/class/teacher/classes/list/${uid}/${type}`,
      headers: {
        "Content-Type": "application/json",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

//get class Concepts and parctice list
export const getClassConceptsAndPracticeList = async (classId, uid) => {
  try {
    const res = await request({
      url: `/events/v1/class/teacher/details/${classId}`,
      headers: {
        "Content-Type": "application/json",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

//get class Detail
export const getClassDetailsForTeacher = async (classId, uid) => {
  try {
    const res = await request({
      url: `/events/v1/class/details/${classId}`,
      headers: {
        "Content-Type": "application/json",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

//Toggle CONCEPT(Need sockets later on)
export const toggleConceptForTeacher = async (payload) => {
  try {
    alert('57');
    const res = await request({
      url: `/events/v1/class/teacher/live/${payload.classId}/${payload.currentId}/${payload.currentType}`,
      type: "PATCH",
      headers: {
        "Content-Type": "application/json",
        uid: payload.uid,
      },
      data:{
      "previousId":payload.previousId,
      "classId":payload.classId,
      "previousType":payload.previousType
    }
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// GET ALL SOLUTION
export const getAllSolutionForTeacher = async (topicId, uid) => {
  try {
    const res = await request({
      url: `/events/v1/class/teacher/solution/list/${topicId}`,
      headers: {
        "Content-Type": "application/json",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// POST SOLUTION by Teacher
export const postSolutionByTeacher = async (payload, uid) => {
  try {
    const res = await request({
      url: `/events/v1/class/teacher/doubt/solution`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// GET CLASS SUMMARY
export const getClassSummaryForTeacher = async (classId, uid) => {
  try {
    const res = await request({
      url: `/events/v1/class/classSummary/${classId}`,
      headers: {
        "Content-Type": "application/json",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// GET CLASS ASSIGNMENT LEADERBOARD
export const getClassAssignmentLeaderboardForTeacher = async (assignmentId, uid) => {
  try {
    const res = await request({
      url: `/exams/v1/practice/assignment/teacher/leaderboard/${assignmentId}`,
      headers: {
        "Content-Type": "application/json",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// END CLASS BY TEACHER
export const endClassByTeacher = async (classId, eventId, uid) => {
  try {
    const res = await request({
      url: `/events/v1/class/teacher/completed/${classId}/${eventId}`,
      type: "PATCH",
      headers: {
        "Content-Type": "application/json",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// ADD POST BY TEACHER

export const addPostByTeacher = async (classId, payload, contentType, uid) => {
  try {
    const res = await request({
      url: `/events/v1/class/teacher/post/create/${classId}`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": contentType == "json" ? "application/json" : "multipart/form-data",
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
