import {
  Box,
  Checkbox,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  Text,
  UnorderedList,
  Button,
  ModalContent,
  ModalBody,
  Divider,
  ModalFooter,
} from "@chakra-ui/react";
import { SearchIcon, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getDebateParticipants } from "@/api/feed/user/posts/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const ParticipantsModal = ({
  closeParticipants,
  participants,
  setParticipants,
  triggeredFrom,
  setParticipantsShow,
}) => {
  const { _id: uid } = useSelector((state) => state.userData);
  const [searchQuery, setSearchQuery] = useState("");
  const [friendList, setFriendList] = useState([]);
  const [userList, setUserList] = useState([]);

  const { isLoading, data, isError, error, isPending, isSuccess } = useQuery(
    ["getDebateParticipants", uid],
    () => getDebateParticipants(uid),
    {
      onSuccess: (res) => {
        setFriendList(res?.data?.data?.friendList);
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    }
  );

  useEffect(() => {
    // Filter the friend list based on the search query
    if (searchQuery.length > 2) {
      const filteredList = friendList.filter((user) =>
        user?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setUserList(filteredList);
    } else {
      setUserList(friendList);
    }
  }, [searchQuery, friendList]);

  const handleParticipants = (obj) => {
    const participantExists = participants.some((item) => item._id === obj._id);
    if (participantExists) {
      const filterParticipants = participants.filter(
        (item) => item._id !== obj._id
      );
      setParticipants(filterParticipants);
    } else {
      setParticipants((prev) => [...prev, obj]);
    }
  };

  const checkParticipants = (obj) => {
    return participants.some((item) => item._id === obj._id);
  };

  console.log("party", userList);

  return (
    <>
      <ModalContent
        maxW="xl"
        bg="white.900"
        rounded="2xl"
        color="#000"
        height="60vh"
      >
        <ModalBody overflowY="scroll" overflowX="hidden">
          <Box>
            <Flex alignItems="center" gap="3" pb="6">
              <Box onClick={closeParticipants}>
                <X cursor={"pointer"} />
              </Box>
              <Text fontSize="18px" fontWeight="600">
                Add Participants
              </Text>
            </Flex>
            <InputGroup size="md">
              <InputLeftElement>
                <SearchIcon />
              </InputLeftElement>
              <Input
                pl="3.0rem"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
            <UnorderedList listStyleType="none">
              {userList.length === 0 ? (
                <Text marginTop={4} textAlign={"center"}>
                  No Friends!
                </Text>
              ) : (
                userList.map((item, ind) => (
                  <ListItem
                    key={ind}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pr="4"
                    pt="5"
                  >
                    <Box display="flex" alignItems="center">
                      <Image
                        boxSize="2.5rem"
                        fit="cover"
                        borderRadius="md"
                        src={item?.uid.profilePic}
                        alt="Fluffybuns the destroyer"
                        mr="12px"
                      />
                      <Box>
                        <p style={{ fontSize: "16px", color: "#171717" }}>
                          {item?.uid.name}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#636363",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Master Level
                        </p>
                      </Box>
                    </Box>
                    <Checkbox
                      size="lg"
                      colorScheme="green"
                      isChecked={checkParticipants(item.uid)}
                      onChange={() => handleParticipants(item.uid)}
                    />
                  </ListItem>
                ))
              )}
            </UnorderedList>
          </Box>
        </ModalBody>
        <Divider />
        <ModalFooter flexDirection="column" alignItems="start">
          <Button
            w="full"
            bg="black"
            color="white"
            fontSize="12px"
            onClick={() => setParticipantsShow(false)}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
};

export default ParticipantsModal;
