import { Box } from "@chakra-ui/react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoUrl }) => {
  return (
    // <ReactPlayer url={videoUrl} controls={true} />
    <video className="max-h-[500px] w-full rounded-lg" controls>
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    // <Box width="100%" height="100%">
    //   <iframe
    //     width="600"
    //     height="400"
    //     src={videoUrl}
    //     title="YouTube video player"
    //     frameborder="0"
    //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    //     allowfullscreen
    //   ></iframe>
    // </Box>
  );
};

export default VideoPlayer;
