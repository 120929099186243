import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Plus } from "lucide-react";
import Image from "next/image";
import React, { useRef, useState, useEffect } from "react";
import { Avatar, AvatarGroup } from "@chakra-ui/react";

const DebateCard = ({
  handleParticipants,
  debateImagePreviews,
  participants,
  handleDebateFileUpload,
}) => {
  const fileInputDebateRef = useRef(null);

  const handleDebateFileSelect = () => {
    fileInputDebateRef.current.click();
  };

  useEffect(() => {}, [participants]);

  console.log("party in card", participants[0]);

  return (
    <>
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          // whiteSpace: "nowrap",
          padding: "10px 0",
          // overflowX: "scroll",
          width: "96%",
          margin: "0 2% 0 2%",
        }}
      >
        {debateImagePreviews.map((preview, index) => (
          <img
            key={index}
            src={preview}
            alt="Preview"
            style={{
              width: "120px",
              height: "120px",
              objectFit: "cover",
              borderRadius: "8px",
              marginRight: "10px",
            }}
          />
        ))}

        <Box
          border="2px dashed #8D96A5"
          borderRadius="md"
          p="4"
          textAlign="center"
          cursor="pointer"
          marginLeft={4}
          onClick={handleDebateFileSelect}
          position="relative"
          width="120px"
          height="120px"
        >
          {/* {!imagePreviews.length && ( */}
          <Plus
            size="24px"
            color="#8D96A5"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          {/* )} */}

          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputDebateRef}
            onChange={handleDebateFileUpload}
          />
        </Box>
      </div>

      <hr style={{ margin: "16px 0", borderColor: "#8D96A5" }} />

      <Flex alignItems="center" justifyContent="space-between" px="4">
        <Button
          bg="transparent"
          border="1px solid #8D96A5"
          rounded="lg"
          p="2"
          onClick={handleParticipants}
        >
          <Box display="flex" alignItems="center">
            <Plus size="14px" />
            <Text fontSize="14px">Add Participants</Text>
          </Box>
        </Button>
        <Box display="flex" alignItems="center" justifyContent="center">
          <AvatarGroup size="md" max={3}>
            {participants?.map((itm, ind) => {
              return (
                <Avatar key={ind} name={itm?.name} src={itm?.profilePic} />
              );
            })}
          </AvatarGroup>
        </Box>
      </Flex>
    </>
  );
};

export default DebateCard;
