import React, { useRef, useState, useEffect } from "react";
import { Box, Image, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { MoreHorizontal } from "lucide-react";

const ColumnButtons = ({
  fileInputRef,
  handleClick,
  handleChange,
  selectedFiles,
  handleOptionButtonClick,
  clickPhoto,
  photoVideoRef,
  handleDocumentChange,
}) => {
  // const photoVideoRef = useRef();
  // const debateRef = useRef();
  // const pollRef = useRef();
  const fileInputRefs = useRef();

  const handleButtonClick = (componentName) => {
    // console.log("checking click photo column button", componentName);
    handleOptionButtonClick(componentName);
  };

  // useEffect(() => {
  //   // console.log("checking click photo column button", clickPhoto);
  //   if (clickPhoto) {
  //     const refs = {
  //       photoVideo: photoVideoRef,
  //       poll: pollRef,
  //       debate: debateRef,
  //     };
  //     const element = refs[clickPhoto]?.current;
  //     // console.log(element, "checking click photo column button");
  //     if (element) {
  //       const clickEvent = new MouseEvent("click", {
  //         view: window,
  //         bubbles: true,
  //         cancelable: false,
  //       });
  //       element.dispatchEvent(clickEvent);
  //       // clickEvent.stopPropagation();
  //     }
  //   }
  // }, [clickPhoto]);

  const handleInputChange = (event) => {
    console.log("Input change detected");
    console.log(event.target.files);
  };

  return (
    <Box alignItems="end">
      <UnorderedList listStyleType="none">
        <ListItem
          onClick={() => {
            // handleClick();
            handleButtonClick("photo");
            photoVideoRef.current.click();
            // handleClick();
          }}
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          alignItems="center"
          // ref={imageRef}
          ref={photoVideoRef}
        >
          <Image alt="img icon" src="/colorImage.svg" />
          <Text>Photo or Video</Text>
          <input
            id="fileInput1"
            type="file"
            multiple
            ref={photoVideoRef}
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </ListItem>
        <ListItem
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          alignItems="center"
          onClick={() => handleButtonClick("poll")}
          // ref={pollRef}
        >
          <Image alt="poll icon" src="/Poll.svg" /> <Text>Poll</Text>
        </ListItem>
        <ListItem
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          alignItems="center"
          onClick={() => handleButtonClick("debate")}
          // ref={debateRef}
        >
          <Image alt="megaphone icon" src="/Megaphone copy.svg" />{" "}
          <Text>Debate</Text>
        </ListItem>
        <ListItem
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          alignItems="center"
          onClick={() => handleButtonClick("meme")}
        >
          <Image alt="megaphone icon" src="/Brush.svg" />{" "}
          <Text>Create Meme</Text>
        </ListItem>
        <ListItem
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          alignItems="center"
          onClick={() => {
            handleClick();
            handleButtonClick("fileUpload");
          }}
        >
          <Image alt="paperchip" src="/Paperclip.svg" />
          <Text>Upload Document</Text>
          <input
            id="fileInput2"
            type="file"
            multiple
            accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={() => {
              console.log("check console in column", fileInputRef);
            }}
          />
        </ListItem>
      </UnorderedList>
      {/* <Box>
        <MoreHorizontal />
      </Box> */}
    </Box>
  );
};
export default ColumnButtons;
