import request from "@/api/request";

export const getAllPost = async (page, limit, uid) => {
  try {
    const res = await request({
      url: `/feed/v1/user/post/all/${page}/${limit}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createPost = async (payload, contentType, uid) => {
  try {
    const res = await request({
      url: `/feed/v1/user/post`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": contentType == "json" ? "application/json" : "multipart/form-data",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const createDebate = async (payload, contentType, uid) => {
  try {
    const res = await request({
      url: `/feed/v1/user/post/debate`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": contentType == "json" ? "application/json" : "multipart/form-data",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editPost = async (postId, payload) => {
  try {
    const res = await request({
      url: `/feed/v1/user/post/${postId}`,
      type: "PATCH",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getDebateParticipants = async (uid) => {
  try {
    const res = await request({
      url: `/feed/v1/user/post/debate/participants/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
//get Topics for user feed
export const getTopicsForFeed = async () => {
  try {
    const res = await request({
      url: `/exams/v1/topic`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPostDetailById = async (uid, postId) => {
  try {
    const res = await request({
      url: `/feed/v1/user/post/detail/${postId}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data.data.post;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
