import { Box, HStack, Image, ListItem, UnorderedList } from "@chakra-ui/react";
import { MoreHorizontal } from "lucide-react";
import React, { use, useEffect, useRef } from "react";
import { memo } from "react";

const RowButton = ({
  fileInputRef,
  handleClick,
  handleChange,
  handleOptionButtonClick,
  selectedFiles,
  clickPhoto,
  handleDocumentChange,
  photoVideoRef,
}) => {
  const imageBTnRef = useRef();
  const debateRef = useRef();
  const pollRef = useRef();

  const handleButtonClick = (componentName) => {
    handleOptionButtonClick(componentName);
  };

  useEffect(() => {
    // console.log("checking click photo column button", clickPhoto);
    if (clickPhoto) {
      const refs = {
        photoVideo: photoVideoRef,
        poll: pollRef,
        debate: debateRef,
      };
      const element = refs[clickPhoto]?.current;
      // console.log(element, "checking click photo column button");
      if (element) {
        const clickEvent = new MouseEvent("click", {
          view: window,
          bubbles: false,
          cancelable: false,
        });
        element.dispatchEvent(clickEvent);
        clickEvent.stopPropagation();
      }
    }
  }, [clickPhoto]);

  // useEffect(() => {
  //   const handleClickPhoto = () => {
  //     let clickEvent = new MouseEvent("click", {
  //       view: window,
  //       bubbles: true,
  //       cancelable: false,
  //     });
  //     const element = imageBTnRef.current;
  //     if (element) {
  //       element.dispatchEvent(clickEvent);
  //       clickEvent.stopPropagation();
  //     }
  //   };

  //   if (clickPhoto) {
  //     handleClickPhoto();
  //   }
  // }, []);

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      p="0"
      width="full"
    >
      <UnorderedList
        cursor="pointer"
        listStyleType="none"
        gap="6"
        display="flex"
      >
        <ListItem
          onClick={() => {
            // handleClick();
            photoVideoRef.current.click();
            handleButtonClick("photo");
          }}
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          // ref={imageBTnRef}
        >
          <Image alt="img icon" src="/colorImage.svg" />
          <input
            id="fileInput1"
            type="file"
            multiple
            accept="image/*,video/*"
            ref={photoVideoRef}
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </ListItem>
        <ListItem
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          onClick={() => handleButtonClick("poll")}
        >
          <Image alt="poll icon" src="/Poll.svg" />
        </ListItem>
        <ListItem
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          onClick={() => handleButtonClick("debate")}
        >
          <Image alt="megaphone icon" src="/Megaphone copy.svg" />
        </ListItem>
        <ListItem
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          onClick={() => handleButtonClick("memes")}
        >
          <Image alt="megaphone icon" src="/Brush.svg" />
        </ListItem>
        <ListItem
          cursor="pointer"
          py="3"
          display="flex"
          gap="4"
          onClick={() => {
            handleClick();
            handleButtonClick("fileUpload");
          }}
        >
          <Image alt="paperchip" src="/Paperclip.svg" />
          <input
            id="fileInput2"
            type="file"
            multiple
            accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleDocumentChange}
          />
        </ListItem>
      </UnorderedList>
      <Box>
        <MoreHorizontal />
      </Box>
    </HStack>
  );
};

export default RowButton;
