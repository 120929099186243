import React, { useCallback, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Divider,
} from "@chakra-ui/react";
import { ChevronDown } from "lucide-react";
import { useRef } from "react";
import RowButton from "../../homePostComponents/rowButton";
import ParticipantsModal from "../../homePostComponents/participantsModal";
import CreateMemeModal from "../../homePostComponents/createMemeModal";
import { createDebate } from "@/api/feed/user/posts";
import { createGroupPost } from "@/api/feed/groups/post";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPost } from "@/api/feed/user/posts";
import { DataURIToBlob } from "@/utilities/utilityFunction";
import CreateBasicModal from "../../homePostComponents/createBasicModal";
import randomstring from "randomstring";
import ColumnButtons from "../../homePostComponents/columnButtons";

const DiscussionModal = ({
  isOpen,
  onClose,
  clickPhoto,
  triggeredFrom,
  finalRef,
  setSelectedComponent,
  selectedComponent,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [text, setText] = useState("");
  const [IsPostLoading, setIsPostLoading] = useState(false);
  const fileInputRef = useRef(null);
  const documentInputRef = useRef(null);
  const photoVideoRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedDocFiles, setSelectedDocFiles] = useState([]);

  const [pollOption, setPollOption] = useState(false);
  const [participantsShow, setParticipantsShow] = useState(false);
  const [createMemeShow, setCreateMemeShow] = useState(false);
  const [options, setOptions] = useState([{ id: 1, title: "" }]);
  const [selectedType, setSelectedType] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [privacyType, setPrivacyType] = useState("public");
  const [tempFiles, setTempFiles] = useState([]);
  const [tempDocFiles, setTempDocFiles] = useState([]);
  const [hashTags, setHashTags] = useState([]);
  const [taggedPeople, setTaggedPeople] = useState([]);
  const [taggedTopic, setTaggedTopic] = useState([]);
  const queryClient = useQueryClient();
  const { name, _id: uid } = useSelector((state) => state.userData);
  const { _id: groupId } = useSelector((state) => state.groupData);
  const [debateImagePreviews, setDebateImagePreviews] = useState([]);
  const [DebateMedia, setDebateMedia] = useState([]);

  const handleDebateFileUpload = (event) => {
    const file = event.target.files[0];
    const files = event.target.files;

    setDebateMedia([...DebateMedia, ...files]);
    const reader = new FileReader();
    reader.onload = () => {
      setDebateImagePreviews([...debateImagePreviews, reader.result]);
    };
    reader.readAsDataURL(file);
  };

  console.log("checking debate files media", debateImagePreviews, DebateMedia);

  const handleTypingStart = useCallback(() => {
    setIsTyping(true);
  }, []);

  const handleButtonClick = useCallback((type) => {
    setSelectedType(type);
  }, []);

  const handleClick = useCallback(() => {
    fileInputRef.current.click();
    console.log("handle click ??>>>>>>>>>>>>>>>>>>>>");
  }, []);
  console.log(
    "?>>>>>>>>>>>>>>",
    selectedFiles,
    selectedComponent,
    tempFiles,
    selectedType
  );

  const handleChange = async (event) => {
    const files = event.target.files;
    console.log("handle change ?>>>>>>>>>>>>>>>");
    setTempFiles([...tempFiles, ...files]);

    // Determine if the files are photos or videos
    let isPhoto = false;
    let isVideo = false;

    Object.keys(files).forEach((item) => {
      const file = files[item];
      console.log("************************", file.type);
      if (file.type.startsWith("image")) {
        isPhoto = true;
      } else if (file.type.startsWith("video")) {
        isVideo = true;
      }
    });

    let fileList = Object.keys(files).map((item) => {
      return URL.createObjectURL(files[item]);
    });

    setSelectedFiles([...selectedFiles, ...fileList]);
    console.log("*****", selectedComponent);

    // Set selected component based on the type of files
    if (isPhoto && !isVideo) {
      setSelectedComponent("photo");
    } else if (!isPhoto && isVideo) {
      setSelectedComponent("photo");
    } else {
      setSelectedComponent("text"); // Set to a default value or leave it unchanged
    }
    console.log("bbbbbbbbbbbbbbbbbb", tempFiles, fileList);
  };

  const handleDocumentChange = (event) => {
    const files = Array.from(event.target.files);
    setTempDocFiles([...tempDocFiles, ...files]);

    let fileList = files.map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
    }));

    setSelectedDocFiles([...selectedDocFiles, ...fileList]);
    setSelectedComponent("fileUpload");
  };

  const handleCreatePost = async (data) => {
    if (selectedComponent == "poll") {
      let pollData = {
        title: text,
        privacyType: privacyType,
        options: options,
        postType: "poll",
      };
      if (triggeredFrom == "group") {
        pollData.groupId = groupId;
      }
      mutation.mutate(pollData, "json");
    }
    if (selectedComponent == "debate") {
      const formData = new FormData();

      // let debateData = {
      //   title: text,
      //   privacyType: privacyType,
      //   participants: participants,
      //   postType: "debate",
      // };

      formData.append("title", text);
      formData.append("privacyType", privacyType);
      formData.append("postType", "debate");

      for (let i = 0; i < DebateMedia.length; i++) {
        formData.append("media", DebateMedia[i]);
      }
      for (let i = 0; i < participants.length; i++) {
        formData.append("participants", participants[i]._id);
      }
      if (triggeredFrom == "group") {
        formData.append("groupId", groupId);
      }
      mutation.mutate(formData, "form");
    }
    if (selectedComponent == "photo") {
      const formData = new FormData();
      // Append each file to the FormData object
      formData.append("postType", selectedComponent);
      for (let i = 0; i < tempFiles.length; i++) {
        if (tempFiles[i].type.includes("video")) {
          setSelectedComponent("video");
          formData.set("postType", "video");
        }
        formData.append("file", tempFiles[i]);
      }

      formData.append("title", text);
      formData.append("privacyType", privacyType);
      for (let i = 0; i < taggedPeople.length; i++) {
        formData.append("taggedPeople", taggedPeople[i]);
      }
      for (let i = 0; i < taggedTopic.length; i++) {
        formData.append("taggedTopic", taggedTopic[i]);
      }
      for (let i = 0; i < hashTags.length; i++) {
        formData.append("hashtags", hashTags[i]);
      }
      if (triggeredFrom == "group") {
        formData.append("groupId", groupId);
      }
      mutation.mutate(formData, "form");
      setIsPostLoading(true);
    }

    if (selectedComponent == "fileUpload") {
      console.log("fileUpload Selected");
      const formData = new FormData();
      formData.append("postType", "doc");
      for (let i = 0; i < tempDocFiles.length; i++) {
        formData.append("file", tempDocFiles[i]);
      }
      formData.append("title", text);
      formData.append("privacyType", privacyType);
      for (let i = 0; i < taggedPeople.length; i++) {
        formData.append("taggedPeople", taggedPeople[i]);
      }
      for (let i = 0; i < taggedTopic.length; i++) {
        formData.append("taggedTopic", taggedTopic[i]);
      }
      for (let i = 0; i < hashTags.length; i++) {
        formData.append("hashtags", hashTags[i]);
      }
      if (triggeredFrom == "group") {
        formData.append("groupId", groupId);
      }
      // console.log("Document files selected", formData);
      mutation.mutate(formData, "form");
      setIsPostLoading(true);
    }
    if (selectedComponent == "memes") {
      let file = DataURIToBlob(data.dataUrl);
      const formData = new FormData();
      const filename = randomstring.generate(8) + ".jpg";
      formData.append("file", file, filename);
      formData.append("postType", "memes");
      formData.append("title", data.text);
      formData.append("privacyType", privacyType);
      for (let i = 0; i < taggedPeople.length; i++) {
        formData.append("taggedPeople", taggedPeople[i]);
      }
      for (let i = 0; i < taggedTopic.length; i++) {
        formData.append("taggedTopic", taggedTopic[i]);
      }

      if (triggeredFrom == "group") {
        formData.append("groupId", groupId);
      }
      mutation.mutate(formData, "form");
    }
    if (selectedComponent == "text") {
      let textData = {
        title: text,
        privacyType: privacyType,
        postType: "text",
      };
      if (triggeredFrom == "group") {
        textData.groupId = groupId;
      }
      mutation.mutate(textData, "json");
    }
  };

  // const handleCreatePost = async (data) => {
  //   let postData = {
  //     title: text,
  //     privacyType: privacyType,
  //     postType: selectedComponent,
  //   };
  //   console.log("********** in create post", selectedComponent);

  //   switch (selectedComponent) {
  //     case "poll":
  //       postData.options = options;
  //       if (triggeredFrom === "group") {
  //         postData.groupId = groupId;
  //       }
  //       break;

  //     case "debate":
  //       postData.participants = participants;
  //       if (triggeredFrom === "group") {
  //         postData.groupId = groupId;
  //       }
  //       break;

  //     case "photo":
  //     case "video":
  //       const formDataPhoto = new FormData();
  //       formDataPhoto.append("postType", selectedComponent);
  //       for (let i = 0; i < tempFiles.length; i++) {
  //         if (tempFiles[i].type.includes("video")) {
  //           setSelectedComponent("video");
  //           formDataPhoto.append("postType", "video");
  //         }
  //         formDataPhoto.append("file", tempFiles[i]);
  //       }
  //       formDataPhoto.append("title", text);
  //       formDataPhoto.append("privacyType", privacyType);

  //       for (let i = 0; i < taggedPeople.length; i++) {
  //         formDataPhoto.append("taggedPeople", taggedPeople[i]);
  //       }
  //       for (let i = 0; i < taggedTopic.length; i++) {
  //         formDataPhoto.append("taggedTopic", taggedTopic[i]);
  //       }
  //       for (let i = 0; i < hashTags.length; i++) {
  //         formDataPhoto.append("hashtags", hashTags[i]);
  //       }
  //       if (triggeredFrom === "group") {
  //         formDataPhoto.append("groupId", groupId);
  //       }
  //       console.log(formDataPhoto, "in post formData");
  //       console.log("in post formData FormData contents:");
  //       for (let pair of formDataPhoto.entries()) {
  //         console.log(pair[0] + ": in post formData " + pair[1]);
  //       }
  //       mutation.mutate(formDataPhoto, "form");
  //       break;

  //     case "memes":
  //       const file = DataURIToBlob(data.dataUrl);
  //       const formDataMemes = new FormData();
  //       const filename = randomstring.generate(8) + ".jpg";
  //       formDataMemes.append("file", file, filename);
  //       formDataMemes.append("title", data.text);
  //       formDataMemes.append("privacyType", privacyType);
  //       for (let i = 0; i < taggedPeople.length; i++) {
  //         formDataMemes.append("taggedPeople", taggedPeople[i]);
  //       }
  //       for (let i = 0; i < taggedTopic.length; i++) {
  //         formDataMemes.append("taggedTopic", taggedTopic[i]);
  //       }
  //       if (triggeredFrom === "group") {
  //         formDataMemes.append("groupId", groupId);
  //       }
  //       mutation.mutate(formDataMemes, "form");
  //       break;

  //     case "text":
  //       if (triggeredFrom === "group") {
  //         postData.groupId = groupId;
  //       }
  //       break;

  //     default:
  //       break;
  //   }

  //   if (selectedComponent !== "photo" && selectedComponent !== "memes") {
  //     mutation.mutate(postData, "json");
  //   }
  // };

  const handleOptionButtonClick = useCallback((componentName) => {
    setPollOption(true);
    setSelectedComponent(componentName);
    if (componentName === "memes") {
      setCreateMemeShow(true);
    }
  }, []);

  const closeParticipants = useCallback(() => {
    setParticipantsShow(false);
  }, []);

  const mutation = useMutation({
    mutationFn: (payload, contentType) => {
      triggeredFrom == "group"
        ? createGroupPost(payload, contentType, uid)
        : selectedComponent == "debate"
        ? createDebate(payload, "form", uid)
        : createPost(payload, contentType, uid);
    },
    onMutate: (variables) => {
      setIsPostLoading(true);
      return console.log("mutation is happening");
    },
    onError: (error, variables, context) => {
      setIsPostLoading(false);
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    onSuccess: (res, variables, context) => {
      toast.success("post created successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsPostLoading(false);
      triggeredFrom == "group"
        ? queryClient.invalidateQueries("getGroupPosts")
        : queryClient.invalidateQueries("getAllPosts");
      setText("");
      setSelectedFiles([]);
      setOptions([]);
      setSelectedComponent("text");
      onClose();
    },
    onSettled: (data, error, variables, context) => {},
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="auto"
        onClose={() => {
          setSelectedComponent(null);
          onClose();
        }}
        finalFocusRef={finalRef}
      >
        <ModalOverlay />
        {selectedComponent == "memes" ? (
          <CreateMemeModal
            handleTypingStart={handleTypingStart}
            selectedType={selectedType}
            fileInputRef={fileInputRef}
            handleClick={handleClick}
            handleOptionButtonClick={handleOptionButtonClick}
            handleChange={handleChange}
            selectedFiles={selectedFiles}
            handleButtonClick={handleButtonClick}
            onClose={onClose}
            createPost={handleCreatePost}
          />
        ) : !participantsShow ? (
          <ModalContent
            maxW="xl"
            position="absolute"
            bg="white.900"
            rounded="2xl"
            color="#000"
            height="60vh"
          >
            <CreateBasicModal
              privacyType={privacyType}
              setPrivacyType={setPrivacyType}
              text={text}
              setText={setText}
              handleTypingStart={handleTypingStart}
              setIsTyping={setIsTyping}
              setTaggedPeople={setTaggedPeople}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              tempFiles={tempFiles}
              setTempFiles={setTempFiles}
              selectedDocFiles={selectedDocFiles}
              setSelectedDocFiles={setSelectedDocFiles}
              tempDocFiles={tempDocFiles}
              setTempDocFiles={setTempDocFiles}
              selectedComponent={selectedComponent}
              options={options}
              setOptions={setOptions}
              setParticipantsShow={setParticipantsShow}
              setTaggedTopic={setTaggedTopic}
              participants={participants}
              setHashTags={setHashTags}
              debateImagePreviews={debateImagePreviews}
              handleDebateFileUpload={handleDebateFileUpload}
              handleDocumentChange={handleDocumentChange}
            />
            <ModalFooter flexDirection="column" alignItems="start">
              {selectedComponent !== "" ? (
                <RowButton
                  fileInputRef={fileInputRef}
                  handleClick={handleClick}
                  handleChange={handleChange}
                  selectedFiles={selectedFiles}
                  handleOptionButtonClick={handleOptionButtonClick}
                  clickPhoto={clickPhoto}
                  handleDocumentChange={handleDocumentChange}
                  photoVideoRef={photoVideoRef}
                />
              ) : (
                <ColumnButtons
                  fileInputRef={fileInputRef}
                  handleClick={handleClick}
                  handleChange={handleChange}
                  selectedFiles={selectedFiles}
                  handleOptionButtonClick={handleOptionButtonClick}
                  clickPhoto={clickPhoto}
                  photoVideoRef={photoVideoRef}
                  handleDocumentChange={handleDocumentChange}
                />
              )}

              <Button
                w="full"
                sx={{
                  bg: "black !important",
                  color: "#fff",
                  fontSize: "12px",
                }}
                variant="solid"
                onClick={() => {
                  !IsPostLoading && handleCreatePost();
                }}
              >
                {IsPostLoading ? "..." : "post"}
              </Button>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ParticipantsModal
            closeParticipants={closeParticipants}
            participants={participants}
            setParticipants={setParticipants}
            triggeredFrom={triggeredFrom}
            setParticipantsShow={setParticipantsShow}
          />
        )}
      </Modal>
    </>
  );
};
export default DiscussionModal;
